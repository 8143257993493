// Here you can add other styles
.table thead th {
    vertical-align: bottom;
    border-bottom: 0;
}

$primary-color: #079ad9;
$default-color:#e4e7ea;



.radio-tile-group {
  display: flex;
  flex-wrap: wrap;

  //set dimensions for invisible container
  .input-container {
    position: relative;
    height:  7rem;
    width:  7rem;
    margin: 0.5rem;

    //make actual radio input invisible
    // + stretch to fill container
    .radio-button {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      margin: 0;
      cursor: pointer;
    }

    //default tile styles
    .radio-tile {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      border: 2px solid $default-color;
      border-radius: 5px;
      padding: 1rem;
      transition: transform 300ms ease;
    }
    .icon svg {
      fill: $default-color;
      width: 3rem;
      height: 3rem;
    }
    .radio-tile-label {
      text-align: center;
      font-size: 0.75rem;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 1px;
    }

    //active tile styles
    .radio-button:checked + .radio-tile {
      background-color: none;
      border: 2px solid $primary-color;
      color: white;
      transform: scale(1.1, 1.1);
      
      .icon svg {
        fill: $primary-color;
        background-color: none;
      }
      .radio-tile-label {
        color: $primary-color;
        background-color: none;
      }
    }
  }
}
/* Timeline */
$link-color: #58B96B;
.timeline {
  position: relative;
  width: 860px;
  /* margin: 0px auto; */
  padding: 20px;
  list-style-type: none;
  @media(max-width: 860px) {
    width: 100%;
    padding: 4em 0 1em 0;
  }
  &:before {
    position: absolute;
    left: 50%;
    top: 0;
    content: ' ';
    display: block;
    width: 6px;
    height: 100%;
    margin-left: -3px;
    background: rgb(80,80,80);
    z-index: 5;
  }
  li {
    padding: 3em 0;
    @media(max-width: 860px) {
      padding: 2em 0;
    }
    &::after {
      content: "";
      display: block;
      height: 0;
      clear: both;
      visibility: hidden;
    }
  }
}


.direction-l {
  position: relative;
  width: 400px;
  float: left;
  text-align: right;
  @media(max-width: 860px) {
    float: none;
    width: 100%;
    text-align: center;
  }
  .flag {
    box-shadow: -1px 1px 1px rgba(0,0,0,0.15);
    &:after {
      content: "";
      position: absolute;
      left: 100%;
      top: 50%;
      height: 0;
      width: 0;
      margin-top: -8px;
      border: solid transparent;
      border-left-color: rgb(248,248,248);
      border-width: 8px;
      pointer-events: none;
      @media(max-width: 860px) {
        content: "";
        position: absolute;
        left: 50%;
        top: -8px;
        height: 0;
        width: 0;
        margin-left: -8px;
        border: solid transparent;
        border-bottom-color: rgb(255,255,255);
        border-width: 8px;
        pointer-events: none;
      }
    }
  }
  .time-wrapper {
    float: left;
    @media(max-width: 860px) {
      float: none;
    }
  }
}


.direction-r {
  position: relative;
  width: 400px;
  float: right;
  @media(max-width: 860px) {
    float: none;
    width: 100%;
    text-align: center;
  }
  .flag {
    box-shadow: 1px 1px 1px rgba(0,0,0,0.15);
    &:after {
      content: "";
      position: absolute;
      right: 100%;
      top: 50%;
      height: 0;
      width: 0;
      margin-top: -8px;
      border: solid transparent;
      border-right-color: rgb(248,248,248);
      border-width: 8px;
      pointer-events: none;
      @media(max-width: 860px) {
        content: "";
        position: absolute;
        left: 50%;
        top: -8px;
        height: 0;
        width: 0;
        margin-left: -8px;
        border: solid transparent;
        border-bottom-color: rgb(255,255,255);
        border-width: 8px;
        pointer-events: none;
      }
    }
    &:before {
      left: -16px;
    }
  }
  .time-wrapper {
    float: right;
    @media(max-width: 860px) {
      float: none;
    }
  }
}

.flag-wrapper {
  position: relative;
  display: inline-block;
  text-align: center;
  @media(max-width: 860px) {
    text-align: center;
  }
  .flag {
    position: relative;
    display: inline;
    background: rgb(248,248,248);
    padding: 6px 10px;
    border-radius: 5px;
    font-weight: 600;
    text-align: left;
    @media(max-width: 860px) {
      background: rgb(255,255,255);
      z-index: 15;
    }
  }
}

.direction-l .flag:before,
.direction-r .flag:before {
  position: absolute;
  top: 63%;
  right: -16px;
  content: ' ';
  display: block;
  width: 12px;
  height: 12px;
  margin-top: -10px;
  background: #fff;
  border-radius: 10px;
  border: 4px solid $link-color;
  z-index: 10;
  @media(max-width: 860px) {
    position: absolute;
    top: -6px;
    left: 55%;
    content: ' ';
    display: block;
    margin-left: -10px;
  }
}



.time-wrapper {
  display: inline;
  line-height: 1em;
  font-size: 0.66666em;
  color: $link-color;
  vertical-align: middle;
  @media(max-width: 860px) {
    display: block;
    position: relative;
    margin: 4px 0 0 0;
    z-index: 14;
  }
  .time {
    display: inline-block;
    padding: 4px 6px;
    background: rgb(248,248,248);
  }
}

.desc {
  margin: 1em 0.75em 0 0;
  font-size: 0.9em;
  font-style: italic;
  line-height: 1.5em;
  a {
    color: $link-color;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  @media(max-width: 860px) {
    position: relative;
    margin: 1em 1em 0 1em;
    padding: 1em;
    background: rgb(245,245,245);
    box-shadow: 0 0 1px rgba(0,0,0,0.20);
    z-index: 15;
    
  }
}
